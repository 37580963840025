import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import WorkStyles from "../styles/WorkStyles";

import Item from "./Item";

const Work = (props) => {
  const { images } = useStaticQuery(graphql`
    query {
      images: allImageSharp {
        nodes {
          fluid(maxWidth: 1000, quality: 95) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <WorkStyles id='work'>
      <Item
        images={{
          imageOne: images.nodes[0].fluid,
          imageTwo: images.nodes[1].fluid,
        }}
      >
        <p>Een greep uit het aanbod van onze dienstverlening:</p>
        <ol>
          <li>Elektriciteit bij nieuwbouw</li>
          <li>Renovatie en uitbreiding elektriciteit installatie</li>
          <li>Algemene elektrische herstellingen en onderhoudswerken</li>
          <li>Plaatsing van verlichting, buitenverlichting en tuinverlichting</li>
          <li>Meterkasten en opstellen van elektrische schema’s</li>
          <li>Ondersteuningswerken bij nieuwe elektrische installaties</li>
          <li>Alle algemene elektriciteitswerken</li>
        </ol>
      </Item>
      <Item
        images={{
          imageOne: images.nodes[2].fluid,
          imageTwo: images.nodes[3].fluid,
        }}
      >
        <p>
          We kiezen bewust voor een persoonlijke aanpak en streven naar een professionele dienstverlening. We voorzien dat alle elektriciteitswerken op maat zijn van uw wensen.
        </p>
        <p>
          We hechten veel belang aan een kwalitatieve en naadloze afwerking volgens de regels van goed vakmanschap en de normen beschreven in het AREI.
        </p>
        <p>Om een feilloze installatie te garanderen maken we enkel gebruik van materialen van hoogwaardige kwaliteit.</p>
      </Item>
      <Item
        images={{
          imageOne: images.nodes[4].fluid,
          imageTwo: images.nodes[5].fluid,
        }}
      >
        <p>
          Met een grote gedrevenheid en passie voor elektriciteit doen we er alles aan om onze service in de algemene elektriciteitswerken continu te verbeteren en blijven we op de hoogte van de laatste nieuwigheden en innovatieve mogelijkheden.
        </p>
        <p>Op die manier staan we u bij met vakkundig advies, kiezen we een duurzame oplossingen en begeleiden we u met aandachtige nazorg in al uw noden voor uw elektriciteitswerken.</p>
        <p>
          Bekijk hier ook een aantal van onze <Link to={"/realisaties/"}>realisaties</Link>
        </p>
      </Item>
      <Item
        images={{
          imageOne: images.nodes[6].fluid,
          imageTwo: images.nodes[7].fluid,
        }}
      >
        <p>
          Stetrics is Uw elektricien voor de regio Heist-op-den-berg. Aarzel niet om <Link to={"/contact/"}>contact</Link> op te nemen. Wij voeren werken uit in:
        </p>
        <ol>
          <li>
            <Link to={"/elektricien-heist-op-den-berg"}>Heist-op-den-Berg</Link> (Centrum, Itegem, Hallaar, Booischot, Wiekevorst)
          </li>
          <li>
            <Link to={"/elektricien-putte"}>Putte</Link> (Beerzel)
          </li>
          <li>
            <Link to={"/elektricien-lier"}>Lier</Link> (Koningshooikt)
          </li>
          <li>
            <Link to={"/elektricien-herentals"}>Herentals</Link> (Herenthout)
          </li>
          <li>
            <Link to={"/elektricien-westerlo"}>Westerlo</Link> (Centrum, Oevel, Tonderlo, Zoerle-Parwijs)
          </li>
          <li>
            <Link to={"/elektricien-antwerpen"}>Antwerpen</Link> (Berchem, Merksem, Deurne, Wilrijk)
          </li>
        </ol>
      </Item>
    </WorkStyles>
  );
};

export default Work;
