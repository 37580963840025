import styled from "styled-components";

const WorkStyles = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 50px;

  @media (min-width: 992px) {
    margin-top: 160px;
  }

  .case {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    align-items: center;

    @media (min-width: 992px) {
      flex-direction: row;
      max-height: 720px;
      &:nth-child(even) {
        flex-direction: row-reverse;
      }
    }

    .case_text {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      padding: 20px 15px;

      p {
        margin: 0;
        padding: 8px 0;
      }

      ol {
        list-style-type: disc;
        margin-top: 0;

        li {
          padding: 0;
        }
      }

      a {
        color: var(--black);
        text-decoration: underline;
        text-decoration-color: var(--accent);
      }

      @media (min-width: 440px) {
        padding: 50px 15px;
      }

      @media (min-width: 708px) {
        padding: 150px 15px;
      }

      @media (min-width: 992px) {
        width: 50%;
        padding: 0 50px;
      }
    }

    .case_image {
      width: 100%;

      img {
        width: 100%;
      }

      @media (min-width: 992px) {
        width: 50%;
      }
    }
  }
`;

export default WorkStyles;
