import * as React from "react";

import Contact from "../components/Contact";
import Flavor from "../components/Flavor";
import Header from "../components/Header";
import SEO from "../components/SEO";
import Work from "../components/Work";

const IndexPage = ({ location }) => {
  return (
    <>
      <SEO
        title='Home | Stetrics algemene elektriciteitswerken'
        location={location}
        jsonLD={{
          "@context": "https://schema.org",
          "@type": "Organization",
          url: "http://www.stetrics.be",
          name: "Stetrics",
          contactPoint: {
            "@type": "ContactPoint",
            telephone: "+32-496-82-90-85",
            contactType: "Customer service",
          },
        }}
      />
      <Header />
      <Flavor />
      <Work />
      <Contact />
    </>
  );
};

export default IndexPage;
