import React from "react";

import styled from "styled-components";

const FlavorStyles = styled.div`
  width: 100%;
  text-align: center;
  padding: 0 30px;

  @media (min-width: 440px) {
    padding: 0px 20%;
  }

  h2 {
    color: var(--black);
  }

  p {
    padding: 1rem 0;
  }
`;

const Flavor = () => {
  return (
    <FlavorStyles>
      <h2>Op zoek naar een erkend elektricien?</h2>
      <p>
        Elektricien STETRICS uit Heist-op-den-Berg helpt U graag verder met zowel kleine herstellingen aan Uw elektriciteit installatie alsook grotere elektriciteitswerken. We bieden onze diensten aan
        in de provincies Antwerpen en Vlaams-Brabant.
      </p>
      <p>
        Of het nu gaat om het plaatsen van een bijkomend stopcontact, het plaatsen van nieuwe lichtarmaturen, het conform maken aan het AREI van bestaande installaties of het volledig vernieuwen van
        een elektrische installatie.
      </p>
      <p>Bij ons zit u aan het juiste adres voor al Uw elektriciteitswerken.</p>
    </FlavorStyles>
  );
};

export default Flavor;
