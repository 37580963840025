import React from "react";
import ContactStyles from "../styles/ContactStyles";
import Form from "./Form";

const Contact = () => {
  return (
    <ContactStyles id='contact'>
      <h2>CONTACT</h2>
      <p>
        Wenst u een vrijblijvende <strong>prijsofferte</strong> voor Uw&nbsp;
        <strong>elektriciteitswerken</strong> of liever een&nbsp;
        <strong>afspraak</strong> ter plaatse?
      </p>
      <p>
        Vul hieronder het formulier in! We helpen u graag verder!
      </p>
      <Form />
    </ContactStyles>
  );
};

export default Contact;
