import React from "react";
import Img from "gatsby-image";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import createImageAlt from "../utils/createImageAlt";

const Item = (props) => {
  const fadeProperties = {
    duration: 3000,
    transitionDuration: 500,
    infinite: true,
    indicators: false,
    arrows: false,
  };

  return (
    <div className='case'>
      <div className='case_image'>
        <Fade {...fadeProperties}>
          <Img
            fluid={props.images.imageOne}
            alt={createImageAlt(props.images.imageOne)}
          />
          <Img
            fluid={props.images.imageTwo}
            alt={createImageAlt(props.images.imageTwo)}
          />
        </Fade>
      </div>
      <article className='case_text'>{props.children}</article>
    </div>
  );
};

export default Item;
